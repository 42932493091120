<template>
  <div>
    <div class="modal-card" :style="($mq == 'sm') ? 'width: auto; min-height: 90vh; max-height: 90vh;' : 'width: auto; height: 85vh;'">
      <header class="modal-card-head">
        <p class="modal-card-title">Times Inscritos</p>
      </header>

      <!-- Lista de times inscritos -->
      <section class="modal-card-body" >
        <b-tabs type="is-boxed" class="b-tabs-modal" size="is-small" v-model="tabDefault">
          <b-tab-item label="Rankings">
            <div class="columns is-multiline">
              
              <div class="column is-narrow" v-if="teamsData.length > 0">
                <b-tooltip label="Copiar lista de Times" type="is-primary" position="is-right" animated>
                  <b-button icon-pack="fas" icon-left="copy" type="is-info" @click="copyTeams()">Copiar</b-button>
                </b-tooltip>
                <b-input type="hidden" id="export_csv_file" :value="export_file"></b-input>
              </div>

              <div class="column">
                <b-field>
                  <b-input
                    style="width: 90%;"
                    icon="search"
                    v-model="filter.value"
                    icon-pack="fas"
                    @keyup.native.enter="search('inscritos')"
                  >
                  </b-input>
                  <p class="control">
                    <button class="button is-primary" @click="search('inscritos')">
                      Buscar
                    </button>
                  </p>
                </b-field>
              </div>
            </div>

            <div class="columns is-multiline" v-if="isParcial">
              <!-- <div class="column">
                <b-select v-model="apura_filtro" expanded :disabled="!apuracao" :type="stateFilters ? null : 'is-danger'">
                  <option v-for="(op) of getTiposApuracao" :key="'sel-'+op.key" :value="op.key">{{ op.descricao }}</option>
                </b-select>
              </div> -->
              <div class="column">
                <b-select v-model="filterSubSala.id" expanded :disabled="!isParcial" @input="loadDataInscritos()">
                  <option v-for="(op) of opcoesFiltros" :key="'op-'+op.id_sub_sala_nv2" :value="op.id_sub_sala_nv2">{{ (op.id_tipo_sub_sala == 1 ? 'Rodada ': '') + op.detalhe_apuracao }}</option>
                </b-select>
              </div>
            </div>

            <div class="column is-full" style="padding-left: 0;padding-right: 0">
              <b-table
                v-if="renderTable"
                ref="table"
                :data="dataTable"
                :total="sala.times_inscritos"

                paginated
                backend-pagination
                :current-page="page"
                :per-page="25"
                pagination-position="bottom"
                @page-change="onPageChangeIncritos"
                
                :mobile-cards="true"
                :loading="stateLoading"
                @sort="(field, order) => orderByDataTable(order)"
                default-sort="ranking"
                :default-sort-direction="orderBy"

                detailed
                detail-key="id"
                :opened-detailed="openedDetail"
                @details-open="(row, a) => getDetails(row)"
              >
                <!-- @click="row => openDetails(row.time.id)" -->
                <template slot-scope="props">

                  <b-table-column label="Rkg" field="ranking" sortable >
                    <div class="lgd-table-index centralize-row" @click="toggle(props.row)">
                      <!-- <p>{{ props.index + 1 }}</p> -->
                      {{ props.row.ranking }}
                      <!-- <p>{{ getColocacao[props.index] }}</p> -->
                      <!-- <b-icon icon="medal" v-if="(props.index + 1) < 4"></b-icon> -->
                    </div>
                  </b-table-column>

                  <b-table-column field="time.escudo" label="" width="100">
                    <div class="lgd-align-itens" @click="toggle(props.row)">
                      <img
                        :src="props.row.time.camisa"
                        class="lgd-camisa"
                      />
                      <img
                        :src="props.row.time.escudo"
                        class="lgd-escudo"
                      />
                    </div>
                  </b-table-column>

                  <b-table-column field="time.nome" label="Nome">
                    <div style="cursor: pointer;" @click="toggle(props.row)">
                      {{ props.row.time.nome }}
                    </div>
                  </b-table-column>

                  <b-table-column field="resultado" label="Resultado" numeric right>
                    <p @click="toggle(props.row)">
                      {{ props.row.resultado != null ? (liga.apuracao_tipo.id == 2 ? "C$ " : "") + props.row.resultado.toFixed(2).replace('.', ',') : "-" }}
                    </p>
                  </b-table-column>
                  <!-- <b-table-column v-else field="patrimonio" label="Valorização" centered numeric sortable>
                    <p>
                      {{
                        props.row.patrimonio != null ? props.row.patrimonio.toFixed(2).replace('.', ',') : "-"
                      }}
                    </p>
                  </b-table-column> -->

                  <b-table-column field="premio" label="Premiação" numeric right>
                    <p @click="toggle(props.row)">
                      <b>{{ props.row.premio ? props.row.premio : '-'}}</b>
                    </p>
                  </b-table-column>
                </template>

                <template slot="detail" slot-scope="props">
                  <ListaTimesDetails v-if="props.row.renderDetails" :ligaId="sala.id_liga" :sala="sala" :timeId="props.row.time.id" :rodadaInicio="rodadaInicio" :rodadaFim="rodadaFim" :tipoApuracao="liga.apuracao_tipo"> </ListaTimesDetails>
                </template>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </b-tab-item>

          <b-tab-item label="Meus Times">
            <div class="columns is-multiline" v-if="isParcial">
              <!-- <div class="column">
                <b-select v-model="apura_filtro" expanded :disabled="!apuracao" :type="stateFilters ? null : 'is-danger'">
                  <option v-for="(op) of getTiposApuracao" :key="'sel-'+op.key" :value="op.key">{{ op.descricao }}</option>
                </b-select>
              </div> -->
              <div class="column">
                <b-select v-model="filterSubSala.id" expanded :disabled="!isParcial" @input="reloadAll()">
                  <option v-for="(op) of opcoesFiltros" :key="'op-'+op.id_sub_sala_nv2" :value="op.id_sub_sala_nv2">{{ (op.id_tipo_sub_sala == 1 ? 'Rodada ': '') + op.detalhe_apuracao }}</option>
                </b-select>
              </div>
            </div>
            <b-table
                :data="dataTableMyTeams"
                :mobile-cards="true"
                :loading="stateLoadingMyTeams"

                @sort="(field, order) => orderByDataTable(order)"
                default-sort="ranking"
                :default-sort-direction="orderBy"

                detailed
                detail-key="id"
                :opened-detailed="openedMyDetail"
                @details-open="(row, a) => getDetailsMyTeams(row)"
              >
                <!-- @click="row => openDetails(row.time.id)" -->
                <template slot-scope="props">
                  <b-table-column label="Rkg" field="ranking" sortable>
                    <div class="lgd-table-index centralize-row" @click="toggle(props.row)">
                      <!-- <p>{{ props.index + 1 }}</p> -->
                      {{ props.row.ranking }}
                      <!-- <p>{{ getColocacao[props.index] }}</p> -->
                      <!-- <b-icon icon="medal" v-if="(props.index + 1) < 4"></b-icon> -->
                    </div>
                  </b-table-column>

                  <b-table-column field="time.escudo" label="" width="100">
                    <div class="lgd-align-itens" @click="toggle(props.row)">
                      <img
                        :src="props.row.time.camisa"
                        class="lgd-camisa"
                      />
                      <img
                        :src="props.row.time.escudo"
                        class="lgd-escudo"
                      />
                    </div>
                  </b-table-column>

                  <b-table-column field="time.nome" label="Nome">
                    <div style="cursor: pointer;" @click="toggle(props.row)">
                      {{ props.row.time.nome }}
                    </div>
                  </b-table-column>

<!-- 
                  <b-table-column v-if="liga.apuracao_tipo.id == 1" field="pontos" label="Pontuação" centered numeric sortable >
                    <p>
                      {{
                        props.row.pontos != null ? props.row.pontos.toFixed(2).replace('.', ',') : "-"
                      }}
                    </p>
                  </b-table-column>
                  <b-table-column v-else field="patrimonio" label="Valorização" centered numeric sortable >
                    <p>
                      {{
                        props.row.patrimonio != null ? props.row.patrimonio.toFixed(2).replace('.', ',') : "-"
                      }}
                    </p>
                  </b-table-column>
 -->

                   <b-table-column field="resultado" label="Resultado" numeric right>
                    <p @click="toggle(props.row)">
                      {{ props.row.resultado != null ? (liga.apuracao_tipo.id == 2 ? "C$ " : "") + props.row.resultado.toFixed(2).replace('.', ',') : "-" }}
                    </p>
                  </b-table-column>

                  <b-table-column field="premio" label="Premiação" numeric right>
                    <p @click="toggle(props.row)">
                      <b>{{ props.row.premio ? props.row.premio : '-'}}</b>
                    </p>
                  </b-table-column>

                </template>

                <template slot="detail" slot-scope="props">
                  <ListaTimesDetails v-if="props.row.renderDetails" :ligaId="sala.id_liga" :sala="sala" :timeId="props.row.time.id" :rodadaInicio="rodadaInicio" :rodadaFim="rodadaFim" :tipoApuracao="liga.apuracao_tipo" > </ListaTimesDetails>
                </template>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </section>
                </template>
              </b-table>
          </b-tab-item>
          
        </b-tabs>
        </section>
      <footer class="modal-card-foot">
        <b-button
          type="is-danger"
          @click="$parent.close()"
          outlined
          >Cancelar</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ListaTimesDetails from './ListaTimesDetails'

export default {
  name: "ListaTimes",
  components: {
    ListaTimesDetails,
  },
  props: {
    liga: {
      type: Object,
      required: true
    },
    sala: {
      type: Object,
      required: true
    },
    from: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      tabDefault: 0,
      renderTable: false,
      apuracao: {},
      apura_filtro: '',
      apura_detalhes: '',
      showFiltersRankings: false,
      stateLoading: false,
      stateLoadingMyTeams: false,
      teamsData: [],
      filter: {
        value: '',
        field: 'time'
      },
      page: 1,
      pageMyTeam: 1,
      export_file: '',
      selectRodada: 0,
      meusTimes: [],
      dataTable: [],
      dataTableMyTeams: [],
      stateFilters: false,
      filterSubSala: {
        id: null
      },
      opcoesFiltros: [],
      isParcial: false,
      orderBy: 'asc',
      openedDetail: [],
      openedMyDetail: [],
      subSala: [],
      rodadaInicio: 1,
      rodadaFim: 38
    }
  },
  async created() {

    let loader = this.$loading.show()

    this.tabDefault = (this.from == "trade") ? 1 : 0

    await this.getFilter()

    await Promise.all([
      this.getMercado(),
      this.loadDataInscritos(),
      this.loadDataMyTeams()
    ])

    if (this.liga.rodada_inicio <= this.mercado.rodada_atual && this.liga.rodada_final >= this.mercado.rodada_atual) {
      if (this.mercado.status_mercado == 2) {
        let scope = this
        this.interval = setInterval(function() {
          scope.loadDataInscritos()
          scope.loadDataMyTeams()
        }, 60000)
      }
    }

    this.renderTable = true
    loader.hide()
  },
  computed: {
    ...mapGetters(['userId', 'mercado', 'getLigasTimesUser']),
    getRodadas() {
      let data = []
      let i = parseInt(this.liga.rodada_inicio)
      while (i <= parseInt(this.liga.rodada_final)) {
        if (i < this.mercado.rodada_atual)
          data.push(i)
        else if (this.mercado.status_mercado == 2)
          data.push(i)
        
        i++;
      }
      data = (data) ? data.filter(el => el <= this.mercado.rodada_atual) : []

      return data
    },
    getTiposApuracao() {
      return this.subSala.map(e => {
        return {
          key: e.id_tipo_sub_sala,
          descricao: e.descricao_tipo_sub_sala
        }
      })
    },
    getOpsApuracao() {
      let aux = this.apura_filtro == "" ? 1 : this.apura_filtro
      let data = this.subSala.filter(e => e.id_tipo_sub_sala == aux)[0]
      
      if (data.id_tipo_sub_sala == 3) {
        return [
          { key: 1, descricao: 'Primeiro Turno' },
          { key: 2, descricao: 'Segundo Turno' }
        ]
      }
      else if (data.id_tipo_sub_sala == 1) {
        let i = this.liga.rodada_inicio
        , arr = []
        do {
          arr.push({
            key: i, descricao: `Rodada ${i}`
          })
          i++;
        }
        while (i < this.liga.rodada_final);
        
        return arr
      }
      else if (data.id_tipo_sub_sala == 2) {
        return [
          { key: 1, descricao: 'Janeiro'    },
          { key: 2, descricao: 'Fevereiro'  },
          { key: 3, descricao: 'Março'      },
          { key: 4, descricao: 'Abril'      },
          { key: 5, descricao: 'Maio'       },
          { key: 6, descricao: 'Junho'      },
          { key: 7, descricao: 'Julho'      },
          { key: 8, descricao: 'Agosto'     },
          { key: 9, descricao: 'Setembro'   },
          { key: 10, descricao: 'Outubro'   },
          { key: 11, descricao: 'Novembro'  },
          { key: 12, descricao: 'Dezembro'  },
        ]
      }
      else return null
    }
  },
  watch: {
    'orderBy': {
      handler: function (n,o) {
        if (n != o) {
          this.loadDataInscritos()
        }
      }
    }
  },
  methods: {
    ...mapActions(['getLigasTimesSalas', 'getMercado', 'getTeamsLigas', 'getPartial', 'getHistoryLigas', 'getApuracaoSubSalas', 'getFiltrosApuracao', 'getExportSala', 'getSubSalaByRoom']),
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    async getFilter () {
      try {
        let res = await this.getFiltrosApuracao({ ligaId: this.liga.id, salaId: this.sala.id_sala })

        if (res) {
          let data = res.filter(e => {
            if (e.id_tipo_sub_sala == 1) {
              if (e.detalhe_apuracao <= this.mercado.rodada_atual) return e
            }
            else if (e.id_tipo_sub_sala == 2) {
              if (e.rodada_inicio <= this.mercado.rodada_atual) return e //&& e.rodada_fim >= this.mercado.rodada_atual
            }
            else if (e.id_tipo_sub_sala == 3) {
              if (e.rodada_inicio <= this.mercado.rodada_atual && e.rodada_fim >= this.mercado.rodada_atual) return e
            }
            else {
              return e
            }
          })
          this.filterSubSala.id = data[0].id_sub_sala_nv2
          
          this.opcoesFiltros = data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async orderByDataTable(ev) {
      this.orderBy = ev
    },
    async loadDataInscritos () {
      try {
        this.stateLoading = true

        let params = {
          ligaId: this.liga.id,
          salaId: this.sala.id_sala,
          filters: this.filter,
          page: this.page - 1,
          orderBy: this.orderBy
        }

        if (this.liga.rodada_inicio <= this.mercado.rodada_atual && this.liga.rodada_final >= this.mercado.rodada_atual) {
          if (this.liga.rodada_inicio == this.mercado.rodada_atual && this.mercado.mercado_aberto == 0) {
            this.isParcial = true
            params.parcial = true
            params.snv = this.filterSubSala.id
          }
          else if (this.liga.rodada_inicio < this.mercado.rodada_atual) {
            this.isParcial = true
            params.parcial = true
            params.snv = this.filterSubSala.id
          }
        }
        else if (this.liga.rodada_final < this.mercado.rodada_atual) {
          this.isParcial = true
          params.parcial = true
          params.snv = this.filterSubSala.id
        }

        //await this.getList(1)
        this.teamsData = await this.getLigasTimesSalas(params)

        if (typeof this.teamsData == 'undefined') this.teamsData = []

        this.dataTable = this.teamsData.map((t,i) => {
          if (this.openedDetail.indexOf(t.time.id) !== -1){
            t.renderDetails = true
          }
          return {id: t.time.id, ...t}
        })

        if (this.opcoesFiltros.length > 0){
          this.rodadaInicio = this.opcoesFiltros.find(i => i.id_sub_sala_nv2 == this.filterSubSala.id).rodada_inicio
        } else {
          this.rodadaInicio = this.liga.rodada_inicio
        }
        if (!this.rodadaInicio){
          this.rodadaInicio = 1
        }

        if (this.opcoesFiltros.length > 0){
          this.rodadaFim = this.opcoesFiltros.find(i => i.id_sub_sala_nv2 == this.filterSubSala.id).rodada_fim
        } else {
          this.rodadaFim = this.liga.rodada_final
        }
        if (!this.rodadaFim){
          this.rodadaFim = 38
        }

        this.stateLoading = false
      } catch (err) {
        console.log(err)
        this.stateLoading = false
        this.$toasted.error('Não foi possível buscar informações', {
          duration: 3000,
          position: 'top-center'
        })
      }
    },
    async loadDataMyTeams () {
      try {
        this.stateLoadingMyTeams = true
      
        let params = {
          ligaId: this.liga.id,
          salaId: this.sala.id_sala,
          filters: {
            field: 'usuario',
            value: this.userId
          },
          page: 0,
          orderBy: this.orderBy
        }

        if (this.liga.rodada_inicio <= this.mercado.rodada_atual && this.liga.rodada_final >= this.mercado.rodada_atual) {
          if (this.liga.rodada_inicio == this.mercado.rodada_atual && this.mercado.mercado_aberto == 0) {
            this.isParcial = true
            params.parcial = true
            params.snv = this.filterSubSala.id
          }
          else if (this.liga.rodada_inicio < this.mercado.rodada_atual) {
            this.isParcial = true
            params.parcial = true
            params.snv = this.filterSubSala.id
          }
        }
        else if (this.liga.rodada_final < this.mercado.rodada_atual) {
          this.isParcial = true
          params.parcial = true
          params.snv = this.filterSubSala.id
        }

        let myTeams = await this.getLigasTimesSalas(params)

        if (typeof myTeams == 'undefined') myTeams = []

        this.dataTableMyTeams = myTeams.map((t,i) => {
          if (this.openedMyDetail.indexOf(t.time.id) !== -1){
            t.renderDetails = true
          }
          return {id: t.time.id, ...t}
        })

        if (this.opcoesFiltros.length > 0){
          this.rodadaInicio = this.opcoesFiltros.find(i => i.id_sub_sala_nv2 == this.filterSubSala.id).rodada_inicio
        } else {
          this.rodadaInicio = this.liga.rodada_inicio
        }
        if (!this.rodadaInicio){
          this.rodadaInicio = 1
        }

        if (this.opcoesFiltros.length > 0){
          this.rodadaFim = this.opcoesFiltros.find(i => i.id_sub_sala_nv2 == this.filterSubSala.id).rodada_fim
        } else {
          this.rodadaFim = this.liga.rodada_final
        }
        if (!this.rodadaFim){
          this.rodadaFim = 38
        }

        this.stateLoadingMyTeams = false
      } catch (err) {
        console.log(err)
        this.stateLoadingMyTeams = false
        this.$toasted.error('Não foi possível buscar informações', {
          duration: 3000,
          position: 'top-center'
        })
      }
    },
    openDetails(id) {
      let rodada = this.mercado.rodada_atual

      if (this.filterSubSala.id) {
        let opcao = this.opcoesFiltros.filter(e => e.id_tipo_sub_sala == 1 && e.id_sub_sala_nv2 == this.filterSubSala.id)
        if (opcao.length > 0) {
          rodada = opcao[0].detalhe_apuracao
        }
      }

      this.$router.push({ path: `/painel/ligas/detalhes/${this.liga.id}/${id}/${rodada}` })
    },
    async reloadAll() {
      this.loadDataInscritos()
      this.loadDataMyTeams()
    },
    async search(context) {
      this.loadDataInscritos()
    },
    async onPageChangeIncritos (page) {
      this.page = page
      this.loadDataInscritos()
    },
    async getList(page) {
      if (this.liga.rodada_inicio == this.mercado.rodada_atual || this.mercado.rodada_atual >= 38) {
        if (this.mercado.status_mercado == 1) {
          // somente times
          this.selectRodada = this.mercado.rodada_atual
          await this.getTeamsLigas({ ligaId: this.liga.id, salaId: this.sala.id_sala, page: page - 1, filters: this.filter })
          
        }
        else if (this.mercado.status_mercado == 2) {
          // parciais
          this.selectRodada = this.mercado.rodada_atual
          await this.getPartial({ ligaId: this.ligaId, salaId: this.sala.id_sala, page: page - 1, filters: this.filter })
          
          let scope = this
          this.interval = setInterval(function() {
            scope.getPartial({ ligaId: scope.ligaId, salaId: this.sala.id_sala, page: page - 1, filters: this.filter })
          }, 20000)
        }
      }
      else if (this.liga.rodada_inicio > this.mercado.rodada_atual) {
        // somente times
        this.selectRodada = this.mercado.rodada_atual
        await this.getTeamsLigas({ ligaId: this.ligaId, salaId: this.sala.id_sala, page: page - 1 })
      }
      else if (this.liga.rodada_final < this.mercado.rodada_atual) {
        // histórico
        this.selectRodada = this.liga.rodada_final 
        await this.getHistoryLigas({ ligaId: this.liga.id, salaId: this.sala.id_sala, rodada: this.selectRodada, page:page - 1 })  
      }
    },
    doFilter() {
      if (this.apura_filtro != '') {
        
        if (this.apura_filtro != 4 && this.apura_detalhes == "") {
          this.stateFilters = false
          this.$toasted.error('Selecione uma opção', {
            duration: 3000,
            position: 'top-center'
          })
          return;
        }

        let [subSala] = this.apuracao.filter(e => {
          if (e.detalhes.id_tipo_sub_sala == 1) {
            if (e.detalhes.rodada_inicio == this.apura_detalhes) 
              return e
          }
          if (e.detalhes.id_tipo_sub_sala == 2) {
            return e // todo qual o mes escolhido
          }
          if (e.detalhes.id_tipo_sub_sala == 3) {
            if (this.apura_detalhes == 1 && e.detalhes.rodada_fim <= 19)
              return e
            else if (this.apura_detalhes == 2 && e.detalhes.rodada_fim  <= 39 && e.detalhes.rodada_inicio > 18) 
              return e
          }
          if (e.detalhes.id_tipo_sub_sala == 4) {
            console.log(e)
            return e
          }
        })
        // console.log('subsala', subSala)
        let obj = {}
        subSala.dados.map(e => {
          obj[e.time.id] = e
        })

        let aux = [...this.teamsData]

        this.dataTable = aux.map(e => {
          
          let premio = 0
          let produto = ""
          
          if (this.liga.apuracao_tipo.id == 1){

            e.ranking = obj[e.time.id].rankings.pontos
            premio = (obj[e.time.id].premios.pontos + obj[e.time.id].premios.pontos_fixo)
            produto = obj[e.time.id].premios.pontos_produto
          
          } else {
            
            e.ranking = obj[e.time.id].rankings.patrimonio
            premio = (obj[e.time.id].premios.patrimonio + obj[e.time.id].premios.patrimonio_fixo)
            produto = obj[e.time.id].premios.patrimonio_produto
          
          }

          e.premio = ((premio > 0) ? 'R$ '+premio.toFixed(2).replace('.', ',') : '') + 
                      ((produto && produto !== '') ? ' + '+produto : '')
          e.pontos = obj[e.time.id].time.pontuacao
          e.patrimonio = obj[e.time.id].time.patrimonio
          
          return e
        })
        this.dataTableMyTeams = aux.map(e => {

          let premio = 0
          let produto = ""
          
          if (this.liga.apuracao_tipo.id == 1){

            e.ranking = obj[e.time.id].rankings.pontos
            premio = (obj[e.time.id].premios.pontos + obj[e.time.id].premios.pontos_fixo)
            produto = obj[e.time.id].premios.pontos_produto
          
          } else {
            
            e.ranking = obj[e.time.id].rankings.patrimonio
            premio = (obj[e.time.id].premios.patrimonio + obj[e.time.id].premios.patrimonio_fixo)
            produto = obj[e.time.id].premios.patrimonio_produto

          }

          e.premio = ((premio > 0) ? 'R$ '+premio.toFixed(2).replace('.', ',') : '') + 
                      ((produto && produto !== '') ? ' + '+produto : '')
          e.pontos = obj[e.time.id].time.pontuacao
          e.patrimonio = obj[e.time.id].time.patrimonio

          return e
        }).filter(el => el.usuario.id == this.userId)
        this.$forceUpdate()
      }
      else {
        this.stateFilters = false
        return;
      }
    },
    async copyTeams () {
      this.export_file = (await this.getExportSala({ ligaId: this.liga.id, salaId: this.sala.id_sala })).lista

      let testingCodeToCopy = document.querySelector('#export_csv_file')
      testingCodeToCopy.value = this.export_file
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy');
        if (successful) {
          this.$toasted.show('Times Copiado!', {
            position: 'top-center',
            duration: 2000,
            type: 'success',
            className: 'toasted-class'
          })
        } else {
          this.$toasted.show('Não foi possível copiar, tente novamente!', {
            position: 'top-center',
            duration: 2000,
            type: 'error',
            className: 'toasted-class'
          })
        }
        
      } catch (err) {
        this.$toasted.show('Não foi possível copiar, tente novamente!', {
          position: 'top-center',
          duration: 2000,
          type: 'error',
          className: 'toasted-class'
        })
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    getOps(apuracao) {
      if (apuracao) {
        if (apuracao.length > 0) {
          this.apura_filtro = this.apuracao[0].detalhes.id_tipo_sub_sala
          let aux = ''
          if (this.apura_filtro != 4) {
            let data = this.sala.subSalas.filter(e => e.id_tipo_sub_sala == this.apura_filtro)[0]
            if (data.id_tipo_sub_sala == 3) {
              aux = [
                { key: 1, descricao: 'Primeiro Turno' },
                { key: 2, descricao: 'Segundo Turno' }
              ]
            }
            else if (data.id_tipo_sub_sala == 1) {
              let i = this.liga.rodada_inicio
              , arr = []
              do {
                arr.push({
                  key: i, descricao: `Rodada ${i}`
                })
                i++;
              }
              while (i < this.liga.rodada_final);
              
              aux = arr
            }
            else if (data.id_tipo_sub_sala == 2) {
              aux = [
                { key: 1, descricao: 'Janeiro'    },
                { key: 2, descricao: 'Fevereiro'  },
                { key: 3, descricao: 'Março'      },
                { key: 4, descricao: 'Abril'      },
                { key: 5, descricao: 'Maio'       },
                { key: 6, descricao: 'Junho'      },
                { key: 7, descricao: 'Julho'      },
                { key: 8, descricao: 'Agosto'     },
                { key: 9, descricao: 'Setembro'   },
                { key: 10, descricao: 'Outubro'   },
                { key: 11, descricao: 'Novembro'  },
                { key: 12, descricao: 'Dezembro'  },
              ]
            }
            else aux = ''
          }
          this.apura_detalhes = aux != '' ? aux[0].key : ''
          this.doFilter()
        }
      }
    },
    getDetails(team){
      if (!team.renderDetails){
        // console.log(this.liga)
        team.renderDetails = true
        this.openedDetail.push(team.id)
      }
    },
    getDetailsMyTeams(myTeam){
      if (!myTeam.renderDetails){
        myTeam.renderDetails = true
        this.openedMyDetail.push(team.id)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>

<style scoped>
.cont {
  padding: 0.3em;
} 
.lgd-card {
  padding: 1.5em;
  border-radius: 4px;
}
.card {
  border: 1px solid #252525;
  background-color: #414141;
}
.card-2 {
  border: 1px solid #252525;
  background-color: #414141;
}
.bg-stadium {
  height: 250px;
  /* background-color: #f3f3f3; */
  background-image: url('../../../assets/166062.jpg');
  background-position: 20% 80%;
  background-size: cover;
  background-repeat: no-repeat;
}
.lgd-align-infos {
  padding: 1em 3em;
}
.lgd-align-infos h1{
  color: rgb(34, 34, 34);
  font-size: 2.5em;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 0;
}
.lgd-align-infos p{
  color: black;
  font-size: 0.9em;
  margin: 0;
  opacity: 0.7;
} 

.lgd-title {
  color: white;
  font-size: 1.5em;
  font-weight: bolder;
  text-transform: uppercase;
}
.lgd-text {
  color: white;
  font-size: 0.9em;
}

.lgd-group {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.192); 
}
.lgd-group:last-child {
  margin-bottom: 0;
  border-bottom: none; 
}
.lgd-value {
  font-size: 2em;
  color: rgb(219, 219, 219);
}
.lgd-align-itens {
  position: relative;
  width: 100px;
}
.lgd-camisa {
  width: 50px;
  position: relative;
  top: 50%;
  left: 0%;
  transform: translate(10%, 0%)
}
.lgd-escudo {
  width: 40px;
  position: relative;
  top: 50%;
  left: 0%;
  transform: translate(-35%, 0%)
}
.lgd-table-index p {
  font-size: 1.2em;
  font-weight: bolder;
}
.pontos-table-lgd {
  font-size: 1.7em;
}
.rodadas {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 0;
}
.rodada {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  width: 30px;
  height: 30px;
  color: #00fffd;
  text-align: center;
  border: 1px solid #00fffd;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
.rodada:hover {
  color: #000;
  border: 1px solid rgba(0,255,204,1);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  background-color: #00fffd;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
}
.rodada-title {
  font-size: 1.2rem;
}
.rodada-selected {
  color: #000;
  border: 1px solid rgba(0,255,204,1);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  background-color: #00fffd;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
}
.bg-gray-graph {
  background-color: #333333;
  border-radius: 7px;
  color: white;
  padding: 10px;
}
.buttons-gp {
  margin-bottom: 1rem;
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #333333;
}
.buttons-gp .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
} 
.buttons-gp .button {
  margin-bottom: .5rem;
}

@media (max-width: 768px) {
  .lgd-camisa {
    top: 10%;
    left: 0%;
    transform: translate(10%, 0%)
  }
  .lgd-escudo {
    top: 10%;
    left: 0%;
    transform: translate(-35%, 0%)
  }
}

</style>
