<template>
  <LayoutLeagues
    id="ligas-section"
    :is-loading="loading"
    v-bind="layoutLeagueProperties"
    @click="handlerEveryClick"
  >
    <div class="columns is-centered">
      <div class="column is-half is-vcentered cx-content">
        <div class="columns is-centered is-mobile">
          <div class="column is-narrow is-vcentered">
            <div class="block-info-saldo">
              <label>Saldo para retirada</label>
              <p>R$ {{ wallet.saldo_wallet }}</p>
            </div>
          </div>
          <div class="column is-narrow is-vcentered">
            <div class="block-info-saldo">
              <label>Bônus para jogo</label>
              <p>R$ {{ wallet.saldo_bonus }}</p>
            </div>
          </div>
          <div class="column is-narrow is-vcentered">
            <div class="block-info-saldo">
              <b>
                <label>SALDO TOTAL</label>
                <p>R$ {{ wallet.saldo_wallet + wallet.saldo_bonus }}</p>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-half is-vcentered cx-content centralize">
        <div
          class="columns is-centered is-mobile"
          :class="{ 'is-gapless': $mq == 'sm' }"
        >
          <div
            class="column is-3 is-vcentered"
            :style="{ padding: $mq == 'sm' ? '0 5px !important' : '12px' }"
          >
            <b-button
              :type="view === 1 ? 'is-bluedark2' : 'is-pigeon'"
              :size="$mq === 'sm' ? 'is-small' : ''"
              @click="handlerTable(1)"
              expanded
              ><b>Extrato</b></b-button
            >
          </div>
          <div
            class="column is-3 is-vcentered"
            :style="{ padding: $mq == 'sm' ? '0 5px !important' : '12px' }"
          >
            <b-button
              :type="view === 2 ? 'is-bluedark2' : 'is-pigeon'"
              :size="$mq === 'sm' ? 'is-small' : ''"
              @click="handlerTable(2)"
              expanded
              ><b>Inscrições</b></b-button
            >
          </div>
          <div
            class="column is-3 is-vcentered"
            :style="{ padding: $mq == 'sm' ? '0 5px !important' : '12px' }"
          >
            <b-button
              type="is-success"
              :size="$mq === 'sm' ? 'is-small' : ''"
              @click="openPayment"
              expanded
              ><b>Depositar</b></b-button
            >
          </div>
          <div
            class="column is-3 is-vcentered"
            :style="{ padding: $mq == 'sm' ? '0 5px !important' : '12px' }"
          >
            <b-button
              type="is-magenta"
              :size="$mq === 'sm' ? 'is-small' : ''"
              @click="openPayOut"
              expanded
              ><b>Sacar</b></b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="cx-table-content">
          <trade :view="view"></trade>
        </div>
      </div>
    </div>
  </LayoutLeagues>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LayoutLeagues from "../layout/LayoutLeagues.vue";
import NofityAway from "../components/NotifyAway.vue";
import Trade from "../components/Caixa/Trade";
import Payment from "@/components/payment/Payment";
import Payout from "@/components/payment/Payout";
import Times from "../components/Times";
import UserService from "@/apps/Ligas/services/User";
import InfluencerPageMixin from "../mixins/influencerPage";

export default {
  name: "Caixa",
  mixins: [UserService, NofityAway, InfluencerPageMixin],
  components: {
    trade: Trade,
    Payment,
    Payout,
    Times,
    LayoutLeagues,
  },
  data() {
    return {
      render: false,
      loading: true,
      backPayment: {},
      view: 1,
    };
  },
  async created() {
    this.render = false;
    let loader = this.$loading.show();
    await this.getUserData();

    if (this.isInfluencerPage) {
      this.handleWhenIsInfluencerPage();
    }

    try {
      await this.getWallet(this.userId);

      this.backPayment = {
        integracao: this.$route.query.pIntegracao
          ? this.$route.query.pIntegracao
          : null,
        status: this.$route.query.pStatus ? this.$route.query.pStatus : null,
        referencia: this.$route.query.pReference
          ? this.$route.query.pReference
          : null,
        valor: this.$route.query.pValor ? this.$route.query.pValor : null,
      };

      if (this.backPayment.integracao) {
        let message =
          this.backPayment.status == "success"
            ? "<p>Sua transação foi realizada com sucesso!</p>"
            : this.backPayment.status == "pending"
            ? "<p>Sua transação está pendente, aguardando a confirmação da operação!</p>"
            : this.backPayment.status == "failure"
            ? "<p>Ocorreu um problema em sua transação. Por favor tente novamente!</p><p>Caso o problema persista, entre em contato com o suporte enviando um e-mail para suporte10@escala10.com.br</p>"
            : "";

        let type =
          this.backPayment.status == "pending"
            ? "warning"
            : this.backPayment.status == "failure"
            ? "danger"
            : this.backPayment.status;

        message +=
          "<p><b>REFERÊNCIA: " + this.backPayment.referencia + "</b></p>";
        message += "<p><b>VALOR: R$ " + this.backPayment.valor + "</b></p>";

        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: message,
          type: "is-" + type,
          hasIcon: true,
        });
      }

      this.render = true;
    } catch (err) {
      this.$toasted.error("Não foi possível carregar as informações", {
        duration: 3000,
        position: "top-center",
      });
    }
    this.loading = false;
    loader.hide();
  },
  computed: {
    ...mapGetters(["userId", "wallet"]),
  },
  methods: {
    ...mapActions(["getWallet"]),
    handlerView(view) {
      let minhasLigas = view === 2 ? "?minhasligas" : "";
      this.$router.push(`/painel/ligas${minhasLigas}`);
    },
    handlerTable(table) {
      this.view = table;
    },
    openPayment() {
      this.$buefy.modal.open({
        parent: this,
        component: Payment,
        hasModalCard: true,
        trapFocus: true,
        canCancel: [true, true, true],
        fullScreen: this.$mq === "sm",
        props: {
          product: "Ligas",
        },
      });
    },
    openPayOut() {
      this.$buefy.modal.open({
        parent: this,
        component: Payout,
        hasModalCard: true,
        trapFocus: true,
        canCancel: [true, true, true],
        fullScreen: this.$mq === "sm",
      });
    },
    signout() {},
  },
};
</script>
