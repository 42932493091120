<template>
  <div>
    <div v-if="view === 1" class="cx-table-trade">
      <h3>Extrato</h3>
      <b-table
        id="trades"
        v-if="renderTrades"
        :data="trades"
        :mobile-cards="true"
        :paginated="true"
        :per-page="9"
      >
        <!-- default-sort="id"
              default-sort-direction="desc" -->

        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            field="id"
            label="Data e Hora"
            left
            sortable
            searchable
          >
            {{ props.row.tradeTime }}
          </b-table-column>

          <b-table-column
            field="type_name"
            label="Tipo"
            width="100"
            left
            centered
            searchable
          >
            {{ props.row.type_name }}
          </b-table-column>

          <b-table-column
            field="integration"
            label="Integração"
            centered
            searchable
          >
            {{ props.row.integration }}
            <p v-if="props.row.log">{{ props.row.log }}</p>
          </b-table-column>

          <b-table-column
            field="status"
            label="Status"
            width="100"
            centered
            searchable
          >
            <b-tooltip
              :label="props.row.error"
              :active="props.row.error ? true : false"
              :type="
                props.row.status == 'pendente' ? 'is-warning' : 'is-danger'
              "
              position="is-bottom"
              animated
            >
              <span
                :class="{
                  'tag is-danger': props.row.status == 'rejeitado',
                  'tag is-warning': props.row.status == 'pendente',
                  'tag is-info': props.row.status == 'em análise',
                  'tag is-black': props.row.status == 'devolvido',
                  'tag is-success':
                    props.row.status == 'aprovado' && props.row.type !== 3,
                  'tag is-primary':
                    props.row.status == 'aprovado' && props.row.type == 3,
                }"
              >
                {{ props.row.status }}
              </span>
            </b-tooltip>
          </b-table-column>

          <b-table-column
            field="points"
            label="Valor"
            width="100"
            right
            numeric
          >
            {{ props.row.points.toFixed(2) }}
            <b-icon
              v-if="props.row.points >= 0"
              pack="fas"
              icon="long-arrow-alt-up"
              type="is-success"
              size="is-small"
            ></b-icon>
            <b-icon
              v-if="props.row.points < 0"
              pack="fas"
              icon="long-arrow-alt-down"
              type="is-danger"
              size="is-small"
            ></b-icon>
          </b-table-column>

          <b-table-column
            field="saldo"
            label="Saldo para retirada"
            right
            numeric
          >
            {{ props.row.saldo.toFixed(2) }}
          </b-table-column>

          <b-table-column field="bonus" label="Bônus" width="100" right numeric>
            {{ props.row.bonus.toFixed(2) }}
            <b-icon
              v-if="props.row.bonus >= 0"
              pack="fas"
              icon="long-arrow-alt-up"
              type="is-success"
              size="is-small"
            ></b-icon>
            <b-icon
              v-if="props.row.bonus < 0"
              pack="fas"
              icon="long-arrow-alt-down"
              type="is-danger"
              size="is-small"
            ></b-icon>
          </b-table-column>

          <b-table-column
            field="saldo_bonus"
            label="Saldo de bônus"
            right
            numeric
          >
            {{ props.row.saldo_bonus.toFixed(2) }}
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>Nenhum Registro.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
    <div v-if="view === 2" class="cx-table-trade">
      <h3>Inscrições</h3>
      <b-table
        id="tradesInGame"
        v-if="renderTradesInGame"
        :data="tradesInGame"
        :mobile-cards="true"
        default-sort="datetime"
        default-sort-direction="desc"
        :paginated="true"
        :per-page="8"
      >
        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            field="id"
            label="Data e Hora"
            width="200"
            left
            sortable
            searchable
          >
            {{ props.row.tradeTime }}
          </b-table-column>

          <b-table-column
            field="influencer_name"
            label="Organizador"
            searchable
          >
            <div style="width: max-content;">
              {{ props.row.influencer_name }}
            </div>
          </b-table-column>

          <b-table-column field="liga_name" label="Liga" left searchable>
            <div style="width: max-content;">
              {{ props.row.liga_name }}
            </div>
          </b-table-column>

          <b-table-column field="liga" label="ID Liga/Sala">
            <div v-if="props.row.liga" style="width: max-content;">
              Liga: {{ props.row.liga }} / Sala: {{ props.row.sala }}
            </div>
          </b-table-column>

          <b-table-column field="points" label="Inscrição" right numeric>
            {{ props.row.points.toFixed(2) }}
          </b-table-column>

          <b-table-column field="bonus" label="Inscrição (Bônus)" right numeric>
            {{ props.row.bonus.toFixed(2) }}
          </b-table-column>

          <b-table-column field="team_name" label="Time" width="300" searchable>
            <div class="time-escudo">
              <img :src="props.row.escudo" :alt="props.row.escudo" />
            </div>
            <div class="time-nome">
              {{ props.row.team_name }}
            </div>
          </b-table-column>

          <b-table-column field="ranking" label="Premiação">
            <div v-if="props.row.ranking" style="width: max-content;">
              R$ {{ props.row.ranking_value.toFixed(2).replace(".", ",") }}
              <b-icon
                v-if="props.row.ranking > 0"
                :type="
                  props.row.ranking == 1
                    ? 'is-ouro'
                    : props.row.ranking == 2
                    ? 'is-prata'
                    : props.row.ranking == 3
                    ? 'is-bronze'
                    : 'is-white'
                "
                pack="fas"
                icon="medal"
                size="is-small"
                style="margin-left: 5px;"
              ></b-icon>
              {{ props.row.ranking }}º
            </div>
            <div v-else>
              -
            </div>
          </b-table-column>

          <b-table-column label="Resultado" centered>
            <div
              v-if="props.row.liga && props.row.sala"
              style="width: max-content;"
            >
              <b-button
                size="is-small"
                type="is-pigeon"
                @click="handlerResultados(props.row.liga, props.row.sala)"
              >
                Veja o Ranking!
              </b-button>
            </div>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>Nenhum Registro.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>

    <b-modal
      :active.sync="resultados"
      :width="700"
      :can-cancel="[false, false, false]"
    >
      <lista-times
        :liga="select.liga"
        :sala="select.sala"
        from="trade"
      ></lista-times>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Moment from "moment";
import ListaTimes from "@/components/leagues/components/ListaTimes";

export default {
  name: "Trade",
  components: {
    "lista-times": ListaTimes,
  },
  props: {
    view: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      renderTrades: false,
      renderTradesInGame: false,
      trades: [],
      tradesInGame: [],
      tradesPremiacao: [],
      resultados: false,
      select: { liga: null, sala: null },
    };
  },
  computed: {
    ...mapGetters(["userId", "wallet"]),
  },
  async created() {
    let loader = this.$loading.show();
    await this.loadTrades();
    loader.hide();
  },

  methods: {
    ...mapActions([
      "getWallet",
      "getTrades",
      "getTradesByType",
      "getLigaByRoom",
      "getSubSalaByRoom",
    ]),
    async loadTrades() {
      this.loadingTable = true;

      await this.getWallet(this.userId);

      if (this.wallet) {
        let loader1 = this.$loading.show();

        this.trades = await this.getTrades({
          userId: this.userId,
          walletId: this.wallet.id,
        });

        if (this.trades.length > 0) {
          
          let saldo = 0;
          let saldo_bonus = 0;
          
          this.trades = this.trades.map((el, index) => {
          
            el.points = el.points ? el.points : 0
            el.saldo = el.saldo ? el.saldo : 0
            el.bonus = el.bonus ? el.bonus : 0
            el.saldo_bonus = el.saldo_bonus ? el.saldo_bonus : 0

            let tradeTime = Moment(new Date(el.datetime));
            tradeTime = tradeTime.format("DD/MM/YYYY - HH:mm:ss");

            saldo +=
              (el.type !== 1 && el.type !== 3 && el.status !== "rejected") ||
              (el.type == 3 && el.status == "approved") ||
              (el.type == 1 && "approved,paid".indexOf(el.status) !== -1)
                ? el.points
                : 0;

            saldo_bonus +=
              (el.type !== 1 && el.type !== 3 && el.status !== "rejected") ||
              (el.type == 3 && el.status == "approved") ||
              (el.type == 1 && "approved,paid".indexOf(el.status) !== -1)
                ? el.bonus
                : 0;

            if (el.status) {
              el.status = el.status.toLowerCase();
              if (el.status == "approved" || el.status == "paid") {
                el.status = "aprovado";
              } else if (el.status == "rejected") {
                el.status = "rejeitado";
              } else if ("pending,in_process".indexOf(el.status) !== -1) {
                el.status = "pendente";
              } else if (el.status == "balance") {
                el.status = "em análise";
              } else if (el.status == "refunded") {
                el.status = "devolvido";
              }
            }

            return { ...el, ordem: index + 1, tradeTime, saldo, saldo_bonus };
          });

          this.trades.sort(function(a, b) {
            if (a.ordem && b.ordem) {
              if (a.ordem < b.ordem) return 1;
              else if (a.ordem > b.ordem) return -1;
              else return 0;
            } else return 0;
          });

          this.renderTrades = true;
        }
        loader1.hide();

        let loader2 = this.$loading.show();

        this.tradesInGame = await this.getTradesByType({
          userId: this.userId,
          walletId: this.wallet.id,
          typeId: 2,
        });

        this.tradesPremiacao = await this.getTradesByType({
          userId: this.userId,
          walletId: this.wallet.id,
          typeId: 3,
        });

        if (this.tradesInGame.length > 0) {
          
          this.tradesInGame = this.tradesInGame.map((el, index) => {

            let tradeTime = Moment(new Date(el.datetime));
            tradeTime = tradeTime.format("DD/MM/YYYY - HH:mm:ss");

            let premiacao = this.tradesPremiacao.find(
              (premio) => premio.liga == el.liga && premio.team == el.team
            );
            if (premiacao) {
              el.ranking = premiacao.ranking;
              el.ranking_value = premiacao.ranking_value;
            }

            return { ...el, tradeTime: tradeTime };
          });

          this.renderTradesInGame = true;
        }
        loader2.hide();
      } else {
        this.$toasted.error("Carteira não identificada!", {
          position: "top-center",
          duration: 5000,
          type: "danger",
        });
      }
    },
    async handlerResultados(ligaId, salaId) {
      let response = await this.getLigaByRoom({ ligaId, salaId });

      if (response) {
        this.select = {
          liga: JSON.parse(JSON.stringify(response)),
          sala: JSON.parse(
            JSON.stringify(
              response.salas.find((sala) => sala.id_sala == salaId)
            )
          ),
        };
        if (this.select.liga && this.select.sala) {
          this.select.sala.subSalas = await this.getSubSalaByRoom({
            ligaId: this.select.liga,
            salaId: this.select.sala.id_sala,
          });
          this.resultados = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.trade-table {
  width: 100%;
  height: auto !important;
  overflow-y: auto;
  background-color: #4d4d4d;
  border-radius: 7px;
  padding: 5px;
}

.label-table,
.label-table i {
  font-size: 1rem !important;
}

.time-escudo {
  display: inline-block;
  height: auto;
  width: 10%;
  margin-right: 5px;
  vertical-align: middle;
}
.time-nome {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
}
</style>
