<template>
  <section id="TeamsDetails">
    <div v-if="renderFilter && rodadas.length > 0 && rodadaInicio !== rodadaFim">
      <div class="columns is-multiline is-mobile" v-if="$mq == 'sm'">
        <div class="column is-full" style="padding-bottom: 0">
          <div class="atl-legend" @click="showFilters = !showFilters">
            Escolha uma Rodada <b-icon pack="fas" :icon="showFilters ? 'chevron-up' : 'chevron-down'" size="is-small"></b-icon>
          </div>
          <div class="rodadas-mobile" v-if="showFilters">
            <div v-for="(el, index) of rodadas" :key="index" :class="{'rodada-mobile': true, 'rodada-selected': el == selectRodada}" @click="loadAgain(el)">{{ el }}</div>
          </div>
        </div>
        <div class="column is-full">
          <div style="float: right;">
            <b-tag rounded type="is-dark" style="border: 1px solid white;">TOTAL {{(tipoApuracao.id == 2 ? "C$ " : "") + totalRodada.toFixed(2).replace('.', ',')}}</b-tag>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-mobile" v-else>
        <div class="column is-auto">
          <div class="rodadas">
            <p class="rodada-title">Escolha uma Rodada</p>
            <div v-for="(el, index) of rodadas" :key="index" :class="{'rodada': true, 'rodada-selected': el == selectRodada}" @click="loadAgain(el)">{{ el }}</div>
          </div>
        </div>
        <div class="column is-narrow">
          <div style="margin-top: 25px;">
            <b-tag rounded size="is-large" type="is-dark" style="border: 1px solid white;">TOTAL {{(tipoApuracao.id == 2 ? "C$ " : "") + totalRodada.toFixed(2).replace('.', ',')}}</b-tag>
          </div>
        </div>
      </div>
    </div>
    <div v-if="renderFilter && rodadas.length > 0 && rodadaInicio == rodadaFim">
      <div class="columns is-multiline is-mobile" v-if="$mq == 'sm'">
        <div class="column is-auto">
          <div class="rodadas-mobile">
            <span class="rodada-title"> Rodada </span>
            <div class="rodada-mobile rodada-selected" @click="loadAgain(rodadaInicio)">{{ rodadaInicio }}</div>
            <div style="float: right; margin-top: 7px;">
              <b-tag rounded type="is-dark" style="border: 1px solid white;">TOTAL {{(tipoApuracao.id == 2 ? "C$ " : "") + totalRodada.toFixed(2).replace('.', ',')}}</b-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-mobile" v-else>
        <div class="column is-auto">
          <div class="rodadas">
            <span class="rodada-title"> Rodada </span>
            <div class="rodada rodada-selected" @click="loadAgain(rodadaInicio)">{{ rodadaInicio }}</div>
          </div>
        </div>
        <div class="column is-narrow">
          <div>
            <b-tag rounded size="is-large" type="is-dark" style="border: 1px solid white;">TOTAL {{(tipoApuracao.id == 2 ? "C$ " : "") + totalRodada.toFixed(2).replace('.', ',')}}</b-tag>
          </div>
        </div>
      </div>
    </div>
    <div v-if="render && rodadas.length > 0">
      <div v-if="!hasntTeam && time" class="columns is-multiline is-gapless">
        <div class="column is-full" v-for="jogador of time.atletas" :key="jogador.atleta_id" >
          <div class="block-content-lg">
            <div v-if="sala.capitao == 1 && time.capitao_id == jogador.atleta_id" class="is-cap-object"></div>
            <div class="foto-jogador-lg centralize-row" style="margin-left: 20px">
              <img v-if="jogador.foto != null" :src="jogador.foto" class="img-jogador-lg" :class="{ 'is-cap': sala.capitao == 1 && time.capitao_id == jogador.atleta_id }">
              <i v-else class="fas fa-user-shield"></i>
              
              <div class="centralize">
                <img v-if="jogador.clube.escudo != null" :src="jogador.clube.escudo" class="img-escudo-lg">
                <i v-else class="fas fa-shield-alt" style="margin-left: 15px;"></i>
                <p class="abreviacao-lg">{{ jogador.clube.abreviacao }}</p>
              </div>
              
              <p class="pos-lg">{{ jogador.posicao ? jogador.posicao : "-" }}</p>
              <p class="nome_lg">{{ jogador.apelido ? jogador.apelido : "-" }}<i v-if="sala.capitao == 1 && time.capitao_id == jogador.atleta_id" v-tooltip.top="{ content: 'Capitão', classes: 'home-tooltip' }" class="fas fa-copyright" :style="{ 'font-size': $mq == 'sm' ? '15px !important' : '20px !important' }" style="margin-left: 15px; color: rgb(243, 247, 41)"></i></p>
            </div>
            <div v-if="tipoApuracao.id == 2" class="centralize-row">
              <p v-if="jogador.variacao_num" class="pont-lg" :style="{ 'color': jogador.variacao_num > 0 ? '#36c947' : '#ef3737' }">
                C$ {{ jogador.variacao_num.toFixed(2) }} 
              </p>
              <p v-else class="pont-lg">-</p>
            </div>
            <div v-else class="centralize-row">
              <p v-if="jogador.pontos_num" class="pont-lg" :style="{ 'color': jogador.pontos_num > 0 ? '#36c947' : '#ef3737' }">
                <b-tag v-if="sala.capitao == 1 && time.capitao_id == jogador.atleta_id" rounded :type="getInfoAboutCap(jogador).type" style="margin-right: 10px;">2x <b>{{ jogador.pontos_num/2 }}</b></b-tag>
                {{ jogador.pontos_num.toFixed(2) }} 
              </p>
              <p v-else class="pont-lg">-</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bg-hasnt-team">
        <div class="content has-text-grey has-text-centered">
          <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large">
              </b-icon>
          </p>
          <p>O Mercado ainda está aberto e as escalações dos times estão indisponíveis!</p>
          <p>Aguarde o fechamento do mercado para espiar os times dos outros parças!</p>
        </div>
      </div>
    </div>
    <div v-else class="bg-hasnt-team">
      <div class="content has-text-grey has-text-centered">
        <p>
            <b-icon
              icon="emoticon-sad"
              size="is-large">
            </b-icon>
        </p>
        <p>Somente será possível visualizar a escalação das rodadas anteriores a rodada atual!</p>
        <p>Rodada Atual: {{ mercado.rodada_atual }}</p>
        <p>{{ (rodadaInicio !== rodadaFim) ? "Rodadas da Liga: de "+rodadaInicio+" até "+rodadaFim : "Rodada da Liga: "+rodadaInicio }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TeamsDetails',
  props: {
    ligaId: {
      type: Number,
      required: true,
      default: () => 0
    },
    sala: {
      type: Object,
      required: true,
      default: () => {}
    },
    timeId: {
      type: Number,
      required: true,
      default: () => 0
    },
    rodadaInicio: {
      type: Number,
      required: true,
      default: () => 0
    },
    rodadaFim: {
      type: Number,
      required: true,
      default: () => 0
    },
    tipoApuracao: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      time: {},
      hasntTeam: false,
      render: false,
      renderFilter: false,
      showFilters: false,
      rodadas: [],
      selectRodada: 0,
      totalRodada: 0
    }
  },
  async created() {
    await this.getMercado()
    let loader = this.$loading.show()

    this.renderFilter = false
    if (this.mercado.rodada_atual >= this.rodadaInicio && this.mercado.rodada_atual <= this.rodadaFim){
      if (this.mercado.status_mercado == 1 && this.mercado.rodada_atual > 1){
        this.selectRodada = (this.mercado.rodada_atual - 1)
      } else {
        this.selectRodada = this.mercado.rodada_atual
      }
    } else if (this.mercado.rodada_atual < this.rodadaInicio) {
      this.selectRodada = this.rodadaInicio
    } else if (this.mercado.rodada_atual > this.rodadaFim){
      this.selectRodada = this.rodadaFim
    }
    for (let index = this.rodadaInicio; index <= this.rodadaFim; index++) {
      if (index <= this.mercado.rodada_atual){
        this.rodadas.push(index)
      }
    }
    this.renderFilter = true

    if (this.rodadas.indexOf(this.selectRodada) == -1){
      this.selectRodada = 0
    }

    this.loadAgain(this.selectRodada)

    // this.render = false

    // let rd = this.rodadaInicio
    // if (rd == this.mercado.rodada_atual) {
    //   if (this.mercado.status_mercado == 1) {
    //     this.hasntTeam = true 
    //   }
    //   else {
    //     this.hasntTeam = false
    //   }
    // }
    // let params = {
    //   timeId: this.timeId,
    //   rodada: this.rodadaInicio
    // }
    // this.time = await this.getTeamDetails(params)
    // this.rodadaInicio = rd

    // this.render = true
    loader.hide()
  },
  computed: {
    ...mapGetters(['mercado'])
  },
  methods: {
    ...mapActions(['getTeamDetails', 'getMercado']),

    getInfoAboutCap(jogador) {
      return {
        type: jogador.pontos_num > 0 ? 'is-success' : 'is-danger'
      }
    },
    async loadAgain(rodada) {

      let loader = this.$loading.show()
      
      this.selectRodada = rodada
      this.totalRodada = 0
      this.render = false
      
      // if (this.selectRodada == this.mercado.rodada_atual) {
      //   if (this.mercado.status_mercado == 1) {
      //     this.hasntTeam = true
      //     this.render = true
      //     loader.hide()
      //     return;
      //   }
      //   this.render = true
      //   loader.hide()
      //   return;
      // }

      if (this.selectRodada == this.mercado.rodada_atual && this.mercado.status_mercado == 1) {
        this.hasntTeam = true
        this.render = true
        loader.hide()
        return;
      }

      let params = {
        timeId: this.timeId,
        rodada: this.selectRodada,
        apuracao: this.tipoApuracao.id
      }
      let time = await this.getTeamDetails(params)
      if (!time || time.status == 'error') {
        // this.$toasted.show('Não conseguimos buscar a escalação, tente outra rodada!', {
        //   position: 'top-center',
        //   duration: 3500,
        //   type: 'error',
        //   className: 'toasted-class'
        // })
      }
      else {
        if (time.atletas.length == 0) {
          // this.$toasted.show('Escalação não encontrada, tente outra rodada!', {
          //   position: 'top-center',
          //   duration: 3000,
          //   type: 'error',
          //   className: 'toasted-class'
          // })
          
          params.rodada = this.selectRodada

          time = await this.getTeamDetails(params)
        }
        this.hasntTeam = false

        time.atletas.map(a => {
          a.pontos_num = (this.sala.capitao == 1 && time.capitao_id == a.atleta_id) ? (a.pontos_num * 2) : a.pontos_num
          this.totalRodada += (this.tipoApuracao.id == 2) ? a.variacao_num : a.pontos_num
        })
      }
      
      this.time = time
      this.render = true
      loader.hide()
    }
  }
}
</script>

<style scoped>
.block-header {
  padding: 30px 0;
  /* background-color: #e4e4e4; */
  border-radius: 4px;
  position: relative;
  background-image: url('../../../assets/166062.jpg');
  background-position: 20% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.pos-back-arrow {
  position: absolute;
  top: 1em;
  left: 1em;
}
.block-content-lg {
  height: auto;
  margin: 2.5px 0;
  background-color: #4d4d4d;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  padding: 10px;
  position: relative;
}
.title-23 {
  color: rgb(10, 10, 10);
  font-weight: bold;
  font-size: 1.5em;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .block-content-lg {
    height: auto;
    padding: 8px;
  }
  .pont-lg {
    font-size: 0.7em !important;
  }
  .foto-jogador-lg i{
    font-size: 3em !important;
  }
  .pos-lg {
    font-size: 0.7em !important;
  }
  .nome_lg {
    font-size: 0.7em !important;
    width: 70px;
    margin-left: 15px !important;
  }
  .img-jogador-lg {
    width: 40px !important;
    height: 40px !important;
  }
  .img-escudo-lg {
    margin: 0 10px !important;
    height: 20px !important;
  }
}
.pont-lg {
  font-weight: bold;
  font-size: 1.5em;
  color: rgb(255, 255, 255);
}
.foto-jogador-lg i{
  font-size: 3.2em;
  color: rgb(255, 255, 255);
}
.pos-lg {
  font-size: 1em;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}
.abreviacao-lg {
  font-size: 0.8em;
  margin-top: 3px;
  color: rgb(209, 209, 209);
  font-weight: bolder;
  text-transform: uppercase;
}
.nome_lg {
  font-size: 1.3em;
  color: rgb(255, 255, 255);
  font-weight: bolder;
  text-transform: uppercase;
  margin-left: 20px;
}
.img-jogador-lg {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid rgb(185, 185, 185);
}
.img-escudo-lg {
  margin: 0 15px;
  height: 30px;
}
.is-cap {
  border: 2px solid rgb(226, 155, 0);
}
.is-cap-object {
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0;
  border-style: solid;
  border-width: 22px;
  /* margin: 1.3em 0 1.8em; */
  border-color: rgb(226, 155, 0) transparent transparent  rgb(226, 155, 0);
}
.bg-hasnt-team {
  background-color: #4d4d4d;
  border-radius: 7px;
}

#TeamsDetails .atl-legend {
  width: 100%;
  padding: 5px 10px;
}

#TeamsDetails .rodadas {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 15px;
}
#TeamsDetails .rodadas-mobile {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
}
#TeamsDetails .rodada {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  width: 30px;
  color: #00fffd;
  text-align: center;
  border: 1px solid #00fffd;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
#TeamsDetails .rodada-mobile {
  display: inline-block;
  margin: 5px;
  padding: 3px;
  width: 25px;
  color: #00fffd;
  text-align: center;
  border: 1px solid #00fffd;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
#TeamsDetails .rodada-total {
  display: inline-block;
}
#TeamsDetails .rodada-title {
  font-size: 1.0rem;
}
#TeamsDetails .rodada-selected {
  color: #000;
  border: 1px solid rgba(0,255,204,1);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  background-color: #00fffd;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  vertical-align: ;
}

</style>