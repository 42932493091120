<template>
  <section id="Payout">
    <div v-if="payoutRender">
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="columns" v-if="result == 'success'">
            <div class="column is-2 is-vcentered">
              <b-icon
                type="is-info"
                icon="info-circle"
                pack="fas"
                size="is-large"
              ></b-icon>
            </div>
            <div class="column is-12 is-vcentered">
              <div class="is-vcentered">
                <p class="modal-card-title">RESGATE REALIZADO COM SUCESSO!</p>
                <span>Aguarde a confirmação do resgate em sua conta</span>
              </div>
            </div>
          </div>
          <div class="columns" v-if="result == 'error'">
            <div class="column is-2 is-vcentered">
              <b-icon
                type="is-danger"
                icon="times-circle"
                pack="fas"
                size="is-large"
              ></b-icon>
            </div>
            <div class="column is-12 is-vcentered">
              <div class="is-vcentered">
                <p class="modal-card-title">OCORREU UM PROBLEMA!</p>
                <span>Verifique o erro e solicite o suporte se necessário</span>
              </div>
            </div>
          </div>
          <div class="columns" v-if="result == ''">
            <div class="column is-12 is-vcentered">
              <div class="is-vcentered">
                <p class="modal-card-title">RESGATE DE VALORES</p>
                <small v-if="result == ''"
                  >Confirme seus dados e defina o valor a ser resgatado.</small
                >
              </div>
            </div>
          </div>
        </header>
        <section class="modal-card-body">
          <div
            v-if="result !== ''"
            class="column is-full is-multiline is-vcentered"
          >
            <!-- <b-notification v-if="result == 'error'" type="is-danger" has-icon>
              <div v-html="resultMessage.error"></div>
            </b-notification>
            <b-notification v-if="result == 'success'" type="is-success" has-icon>
              <div v-html="resultMessage.success"></div>
            </b-notification> -->
            <div v-if="result == 'success'">
              <div v-html="resultMessage.success"></div>
            </div>
            <div v-if="result == 'error'">
              <div v-html="resultMessage.error"></div>
            </div>
          </div>

          <div v-if="config" class="columns is-multiline is-gapless">
            <div class="column is-full is-multiline is-vcentered">
              <b-steps
                v-model="activeStep"
                @change="changeStep"
                :has-navigation="false"
              >
                <b-step-item
                  label="Valor"
                  icon-pack="fas"
                  icon="money-bill-wave"
                  type="is-success"
                >
                  <div class="columns is-multiline">

                    <div class="column is-half">
                      <b-field label="Tipo">
                        <template slot="label">
                          <p><label>Tipo de Saldo</label></p>
                          <p><small>Selecione o tipo de saldo que será sacado</small></p>
                        </template>
                        <div class="control">
                          <b-radio
                            v-for="(type, i) of payoutTypes"
                            :key="'saldoType'+i"
                            name="saldoType"
                            type="is-primary"
                            v-model="userData.saldoType"
                            :native-value="type"
                            @input="checkSaldo(type)"
                            style="margin: 10px 0;"
                          >
                            <p>
                              <span class="is-size-6">
                                <b>{{ getSaldoType(type,'name') }}</b>
                              </span>
                            </p>
                            <p>
                            <small class="is-size-7" style="margin-left: 5px;">
                              {{ getSaldoType(type,'saldo') }}
                              {{ getSaldoType(type,'tax_info') }}
                            </small>
                            </p>
                          </b-radio>
                        </div>
                      </b-field>

                      <b-notification 
                        v-if="getSaldoType(userData.saldoType,'max_value')" 
                        type="is-danger" 
                        size="is-small"
                        :closable="false">
                        {{ getSaldoType(userData.saldoType,'max_value_info') }}
                      </b-notification>
                    </div>

                    <div class="column is-half">
                      <b-field label="Valor Bruto">
                        <template slot="label">
                          <p><label>Valor Bruto</label></p>
                          <p><small>Informe o valor sacado de acordo com o tipo de saldo selecionado</small></p>
                        </template>
                        <b-input
                          type="number"
                          ref="amount"
                          name="amount"
                          v-model="userData.amount"
                          step="0.01"
                          min="0.01"
                          :max="getSaldoType(userData.saldoType,'max_value')"
                          @input="validForm('amount')"
                          required
                          validation-message="Valor > 0 e <= saldo"
                        ></b-input>
                      </b-field>

                      <b-field 
                        label="Valor Líquuido"
                        type="is-primary"
                        :message="getSaldoType(userData.saldoType,'tax_desc')"
                      >
                        <template slot="label">
                          <p><label>Valor Líquido</label></p>
                          <p><small>Valor a ser depositado de acordo com o tipo de saldo selecionado</small></p>
                        </template>
                        <b-input
                          type="number"
                          ref="newAmount"
                          name="newAmount"
                          v-model="userData.newAmount"
                          readonly
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </b-step-item>

                <b-step-item
                  label="Conta"
                  icon="bank"
                  type="is-success"
                >
                  <div class="columns is-multiline">

                    <div class="column is-full">
                      <b-field label="Banco">
                        <template slot="label">
                          <p>Banco</p>
                          <small>Selecione o banco da sua conta bancária</small>
                        </template>
                        <banks
                          default=""
                          v-on:select="userData.bank = $event"
                        ></banks>
                      </b-field>
                    </div>

                    <div class="column is-full">
                      <b-field label="Agência">
                        <template slot="label">
                          <p>Tipo de Conta</p>
                          <small>Selecione o tipo de sua conta bancária</small>
                        </template>
                        <div>
                          <b-radio
                            ref="account_type_corrente"
                            name="account_type"
                            v-model="userData.account_type"
                            native-value="corrente"
                            >Conta-Corrente</b-radio
                          >
                          <b-radio
                            ref="account_type_poupanca"
                            name="account_type"
                            v-model="userData.account_type"
                            native-value="poupanca"
                            >Poupança</b-radio
                          >
                          <b-radio
                            ref="account_type_indefinida"
                            name="account_type"
                            v-model="userData.account_type"
                            native-value="indefinida"
                            >Indefinida</b-radio
                          >
                        </div>
                      </b-field>
                    </div>

                    <div class="column is-half">
                      <b-field label="Agência">
                        <template slot="label">
                          <p>Agência</p>
                          <small>Informe sua agência bancária</small>
                        </template>
                        <b-input
                          ref="bank_branch"
                          name="bank_branch"
                          v-model="userData.bank_branch"
                          @input="validForm('bank_branch')"
                          maxlength="8"
                          required
                          validation-message="8 Caracteres permitidos"
                        ></b-input>
                      </b-field>
                    </div>

                    <div class="column is-half">
                      <b-field label="Conta">
                        <template slot="label">
                          <p>Conta</p>
                          <small>Informe sua conta bancária</small>
                        </template>
                        <b-input
                          ref="account"
                          name="account"
                          v-model="userData.account"
                          @input="validForm('account')"
                          maxlength="18"
                          required
                          validation-message="18 Caracteres permitidos"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </b-step-item>

                <b-step-item
                  label="Beneficiário"
                  icon-pack="fas"
                  icon="user"
                  type="is-success"
                >
                  <small>
                    Os dados a serem preenchidos abaixo, deverão ser do titular da conta que receberá o crédito.
                  </small>

                  <div class="columns is-multiline">
                    <div class="column is-half">
                      <b-field label="Nome">
                        <b-input
                          ref="nome"
                          v-model="userData.nome"
                          @input="validForm('nome')"
                          maxlength="40"
                          pattern="[A-Za-zÀ-ú\s]{1,40}"
                          required
                          validation-message="40 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field label="Sobrenome">
                        <b-input
                          ref="sobrenome"
                          v-model="userData.sobrenome"
                          @input="validForm('sobrenome')"
                          maxlength="80"
                          pattern="[A-Za-zÀ-ú\s]{1,80}"
                          required
                          validation-message="80 Caracteres permitidos: A-Z, a-z, À-ú, espaços"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field label="CPF">
                        <b-input
                          ref="cpf"
                          v-model="userData.cpf"
                          @input="validForm('cpf')"
                          maxlength="11"
                          pattern="[0-9]{11}"
                          required
                          validation-message="Apenas números sem formatação com 11 digitos"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field label="Email">
                        <b-input
                          ref="email"
                          type="email"
                          v-model="userData.email"
                          @input="formatField('userData.email') && validForm('email')"
                          icon="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </b-step-item>

                <b-step-item
                  v-if="confirm || process"
                  label="Confirmação"
                  icon-pack="fas"
                  icon="check"
                  type="is-success"
                >
                  <div class="columns is-multiline">
                    <div class="column is-full">
                      <p class="confirm-title">
                        Confirme os dados informados e clique em processar para
                        iniciar a transação
                      </p>
                    </div>
                    <div class="column">
                      <label>Tipo de Saldo</label>
                      <p class="confirm-data has-text-primary">
                        {{ getSaldoType(userData.saldoType,'name') }}
                      </p>
                    </div>
                    <div class="column">
                      <label>Valor Bruto</label>
                      <p class="confirm-data has-text-primary">
                        R$ {{ Number(userData.amount).toFixed(2) }}
                      </p>
                    </div>
                    <div class="column is-narrow">
                      <label>Valor Líquido</label>
                      <p class="confirm-data has-text-primary">
                        R$ {{ Number(userData.newAmount).toFixed(2) }}
                      </p>
                      <p v-if="userData.tax" class="confirm-data has-text-primary is-size-7">
                        {{ getSaldoType(userData.saldoType,'tax_desc') }}
                      </p>
                    </div>
                    <div class="column is-full">
                      <label>Banco</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.bank.id }} - {{ userData.bank.name }}
                      </p>
                    </div>
                    <div class="column is-4">
                      <label>Tipo Conta</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.account_type }}
                      </p>
                    </div>
                    <div class="column is-4">
                      <label>Agência</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.bank_branch }}
                      </p>
                    </div>
                    <div class="column is-4">
                      <label>Conta</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.account }}
                      </p>
                    </div>
                    <div class="column is-full">
                      <label>Nome</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.nome }} {{ userData.sobrenome }}
                      </p>
                    </div>
                    <div class="column">
                      <label>CPF</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.cpf }}
                      </p>
                    </div>
                    <div class="column is-narrow">
                      <label>E-mail</label>
                      <p class="confirm-data has-text-primary">
                        {{ userData.email }}
                      </p>
                    </div>
                  </div>
                </b-step-item>
              </b-steps>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <b-button v-if="back" type="is-pigeon" @click="payoutReturn()"
              >Voltar</b-button
            >
            <b-button
              v-if="config"
              type="is-danger"
              @click="$parent.close()"
              outlined
              >Cancelar</b-button
            >
            <b-button v-if="confirm" type="is-primary" @click="validForm()"
              >Avançar</b-button
            >
            <b-button v-if="process" type="is-success" @click="payoutProcess()"
              >Processar</b-button
            >
            <b-button
              v-if="result !== ''"
              type="is-primary"
              @click="close()"
              >Ok</b-button
            >
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Banks from "@/components/payment/Banks";
import ModalMessageVue from "@/components/modals/ModalMessage.vue";

export default {
  name: "Payout",
  components: {
    banks: Banks,
  },
  data() {
    return {
      payoutRender: false,
      payoutIntegration: [],
      payoutTypes: [],
      userData: {},
      validationMessage: "",
      activeStep: 0,
      config: true,
      back: false,
      confirm: true,
      process: false,
      result: "",
      resultMessage: { error: null, success: null, url: null },
    };
  },
  computed: {
    ...mapGetters(["userId", "userInfo", "wallet"]),
  },
  async created() {
    let loader = this.$loading.show();

    await this.getWallet(this.userId);
    if (this.wallet.saldo_wallet <= 0) {

      this.notify("Saldo indisponível para a realização de saque!")

    } else if (this.userInfo) {

      this.payoutIntegration = await this.getPaymentIntegration({
        operation: 'resgate'
      })

      if (this.payoutIntegration && this.payoutIntegration.length){

        this.payoutIntegration.map(p => {
          this.payoutTypes = [...this.payoutTypes, ...p.types]
        })

        await this.getUser(this.userId);
        this.userData = JSON.parse(JSON.stringify(this.userInfo));
        this.userData.nome = "";
        this.userData.sobrenome = "";
        this.userData.cpf = "";
        this.userData.email = "";
        this.userData.bank = {};
        this.userData.account_type = "";
        this.userData.bank_branch = "";
        this.userData.account = "";
        this.userData.saldoType = this.payoutTypes.find(t => t.product == 'premio');
        this.userData.saldoValue = this.wallet.saldo_premio
        this.userData.amount = this.userData.saldoType 
            ? (this.wallet.saldo_premio > this.getSaldoType(this.userData.saldoType,'max_value') 
              ? this.getSaldoType(this.userData.saldoType,'max_value') 
              : this.wallet.saldo_premio) 
            : this.wallet.saldo_premio;
        this.userData.tax = this.getSaldoType(this.userData.saldoType,'tax')
        this.userData.newAmount = this.getSaldoType(this.userData.saldoType,'newAmount')

        this.payoutRender = true;
      } else {
        this.notify("Processo de saque não disponível no momento! Estamos realizando uma manutenção e logo este processo será liberado novamente.")
      }
    }
    loader.hide();
  },
  methods: {
    ...mapActions(["getUser", "getWallet", "getPaymentIntegration"]),
    formatField: async function (field) {
      switch (field) {
        case "userData.email":
          this.userData.email = this.userData.email.toLowerCase();
          this.userData.email = this.userData.email.replace(/ /g, "");
          break;
      }
    },
    checkSaldo(tpSaldo) {

      if (tpSaldo && tpSaldo.product){

        let data = JSON.parse(JSON.stringify(this.userData))
        let saldo = this.wallet[`saldo_${tpSaldo.product}`]
        let maxValue = this.getSaldoType(tpSaldo,'max_value')

        data.saldoValue = saldo ? saldo : 0

        data.amount = (data.amount > 0 && data.amount > saldo) 
          ? ((saldo > maxValue)
            ? maxValue 
            : saldo)
          : ((data.amount > 0)
            ? data.amount
            : ((saldo > maxValue)
            ? maxValue 
            : saldo));

        this.userData = JSON.parse(JSON.stringify(data))
        
        this.userData.tax = this.getSaldoType(tpSaldo,'tax')
        this.userData.newAmount = this.getSaldoType(tpSaldo,'newAmount')
      }
    },
    getSaldoType(tpSaldo, info){

      let response = ''
      
      if (tpSaldo){

        switch (info){
        
          case 'saldo':
            if (tpSaldo.product){
              response = this.wallet[`saldo_${tpSaldo.product}`] ? this.wallet[`saldo_${tpSaldo.product}`] : 0
              response = `saldo: R$ ${response.toFixed(2)}`
            }
            break;
          
          case 'max_value_info':
            response = (tpSaldo.max_value) ? `Valor de saque limitado por operação: R$ ${tpSaldo.max_value.toFixed(2)}` : ''
            break;

          case 'tax':
            response = (tpSaldo.tax_percent) ? (Number(this.userData.amount) * (tpSaldo.tax_percent/100)) : 0
            response += (tpSaldo.tax_value) ? tpSaldo.tax_value : 0
            break;

          case 'tax_info':
            response = (tpSaldo.tax_value) ? `R$ ${tpSaldo.tax_value.toFixed(2)}` : ''
            response += (tpSaldo.tax_percent) ? ((response !== '') ? ' + ' : '') + tpSaldo.tax_percent.toFixed(2)+'%' : ''
            response = (response !== '') ? `taxa: ${response}` : ''
            break;
          
          case 'tax_desc':
            let tax_desc = this.getSaldoType(tpSaldo, 'tax')
            response = (tax_desc) ? `Valor Bruto R$ ${Number(this.userData.amount).toFixed(2)} - Taxa R$ ${tax_desc.toFixed(2)}` : ''
            break;

          case 'newAmount':
            let tax = this.getSaldoType(tpSaldo, 'tax')
            response = (Number(this.userData.amount) - tax).toFixed(2)
            break;

          default:
            if (tpSaldo[info]){
              response = tpSaldo[info]
            }
            break;
        }
      }

      return response
    },
    validForm: async function (field) {
      let valid = true;
      let msgAlert = "";

      this.validationMessage = "";

      switch (field) {
        case "nome":
          this.userData.nome = this.userData.nome.trim();
          valid = this.$refs.nome.isValid;
          break;
        case "sobrenome":
          this.userData.sobrenome = this.userData.sobrenome.trim();
          valid = this.$refs.sobrenome.isValid;
          break;

        case "cpf":
          this.userData.cpf = this.userData.cpf.trim();
          valid = this.$refs.cpf.isValid;
          break;

        case "email":
          this.userData.email = this.userData.email.trim();
          valid = this.$refs.email.isValid;
          break;

        case "bank_branch":
          this.userData.bank_branch = this.userData.bank_branch.trim();
          valid = this.$refs.bank_branch.isValid;
          break;

        case "account":
          this.userData.account = this.userData.account.trim();
          valid = this.$refs.account.isValid;
          break;

        case "amount":
          valid = this.$refs.amount.isValid;

          if (valid) {
            let saldoWallet = `saldo_${this.getSaldoType(this.userData.saldoType,'product')}`
            let maxValue = this.getSaldoType(this.userData.saldoType, 'max_value')

            if (this.wallet && saldoWallet in this.wallet){
              valid =
                Number(this.userData.amount) > 0 &&
                Number(this.userData.amount) <=
                  (Number(this.wallet[saldoWallet]) >= maxValue
                    ? maxValue
                    : Number(this.wallet[saldoWallet]));
              if (!valid) {
                this.$refs.amount.isValid = valid;
                // msgAlert =
                //   `O Valor de resgate deverá ser maior do que zero e menor ou igual ao saldo, limitados a R$ ${maxValue.toFixed(2)} por saque.`;
              } else {
                this.checkSaldo(this.userData.saldoType)
              }
            }
          }

          break;

        default:
          if (this.activeStep == 0) {

            this.$refs.amount.checkHtml5Validity();

            if (!this.$refs.amount.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Valor";
            }
            if (msgAlert !== "") {
              msgAlert = "* Campos Obrigatórios: " + msgAlert;
            }

            let saldoWallet = `saldo_${this.getSaldoType(this.userData.saldoType,'product')}`
            let maxValue = this.getSaldoType(this.userData.saldoType, 'max_value')
            if (this.wallet && saldoWallet in this.wallet){
              valid =
                Number(this.userData.amount) > 0 &&
                Number(this.userData.amount) <=
                  (Number(this.wallet[saldoWallet]) >= maxValue
                    ? maxValue
                    : Number(this.wallet[saldoWallet]));
              if (!valid) {
                msgAlert +=
                  (msgAlert !== "" ? ";<br>" : "") +
                  `* O Valor de resgate deverá ser maior do que zero e menor ou igual ao saldo, limitados a R$ ${maxValue.toFixed(2)} por saque.`;
              } else {
                this.checkSaldo(this.userData.saldoType)
              }
            }

          } else if (this.activeStep == 1) {

            this.userData.bank_branch = this.userData.bank_branch.trim();
            this.userData.account = this.userData.account.trim();

            this.$refs.bank_branch.checkHtml5Validity();
            this.$refs.account.checkHtml5Validity();

            if (this.userData.account_type == "") {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Tipo de Conta";
            }
            if (!this.userData.bank.id) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Banco";
            }
            if (!this.$refs.bank_branch.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Agência";
            }
            if (!this.$refs.account.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Conta";
            }
            if (msgAlert !== "") {
              msgAlert = "* Campos Obrigatórios: " + msgAlert;
            }

          } else {

            this.userData.nome = this.userData.nome.trim();
            this.userData.sobrenome = this.userData.sobrenome.trim();
            this.userData.cpf = this.userData.cpf.trim();
            this.userData.email = this.userData.email.trim();

            this.$refs.nome.checkHtml5Validity();
            this.$refs.sobrenome.checkHtml5Validity();
            this.$refs.cpf.checkHtml5Validity();
            this.$refs.email.checkHtml5Validity();

            if (!this.$refs.nome.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Nome";
            }
            if (!this.$refs.sobrenome.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "Sobrenome";
            }
            if (!this.$refs.cpf.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "CPF";
            }
            if (!this.$refs.email.isValid) {
              msgAlert += (msgAlert !== "" ? ", " : "") + "E-Mail";
            }
          }

          break;
      }

      if (!valid && msgAlert !== "") {
        valid = false;
        this.validationMessage =
          "Verifique os campos inconsistentes:<br>" + msgAlert;
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: this.validationMessage,
          type: "is-warning",
          hasIcon: true,
        });
      } else if (!field) {
        this.activeStep++;
        if (this.activeStep == 3) {
          this.confirm = false;
          this.process = true;
        }
      }

      return valid;
    },
    async changeStep(index) {
      this.back = index > 0;
    },
    async payoutReturn() {
      this.confirm = true;
      this.process = false;
      this.activeStep--;
    },
    async payoutProcess() {
      let loader = this.$loading.show();
      let referenceId = new Date().getTime();
      this.userData.reference = referenceId;

      this.$http
        .post(url + "/payment/payout", {
          data: this.userData,
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
        })
        .then(async (response) => {
          this.back = false;
          this.config = false;
          this.confirm = false;
          this.process = false;

          if (response.ok) {
            if (response.body.data) {
              let responseData = response.body.data;

              if (responseData) {
                this.resultMessage = responseData;
              } else {
                this.resultMessage["error"] =
                  "Ocorreu um problema ao processar o resgate. Favor informar ao suporte a seguinte mensagem: responseData não retornado.";
              }
            } else {
              console.error("ERROR_PAYMENT_REGISTER");
              this.resultMessage["error"] =
                "Ocorreu um problema ao processar o resgate. Favor informar ao suporte a seguinte mensagem: ERROR_PAYOUT_REGISTER";
            }
          } else {
            console.error("ERROR_PAYMENT");
            this.resultMessage["error"] =
              "Ocorreu um problema ao processar o resgate. Favor informar ao suporte a seguinte mensagem: ERROR_PAYOUT";
          }

          this.result = this.resultMessage.error ? "error" : "success";
          loader.hide();
        });
    },
    async close() {
      // $parent.close()
      window.location.reload();
    },
    notify(msg) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMessageVue,
        hasModalCard: true,
        trapFocus: true,
        props: {
          message: msg ? 
          `<p>${msg}</p>` : 
          `<p>Ocorreu um problema nos resgate de valor da carteira.</p><p>Tente novamente ou entre em contato com o suporte.</p>`,
          buttonConfirmText: "Falar com o suporte",
          title: "Saque indisponível!",
          hasBody: false,
        },
        events: {
          submit: () => {
            this.$parent.close()
            this.sendMessageToZap({
              text: `Olá! Eu sou ${this.userInfo.nome} (${
                this.userInfo.email
              }). Tive problemas no resgate da carteira, e recebi a seguinte mensagem: ${
                msg ? msg : ''
              }`,
            });
          },
          cancel: () => {
            this.$parent.close()
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.bankLogo {
  height: 30px;
  width: auto;
}
.confirm-title {
  font-size: 1.2em;
  font-weight: bold;
}
.confirm-data {
  font-size: 1.5em;
  font-weight: bolder;
}
</style>  